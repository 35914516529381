@use '@/styles/utils/mixins.scss' as *;

.questionnaireWraper {
  max-width: 52.5rem;
  display: flex;
  flex-direction: column;
  gap: 2.5rem;
  margin-bottom: 3.75rem;
  margin-top: 3.75rem;

  .questionSection {
    max-width: 27.5rem;
    font-size: 0.875rem;
  }
  .radioGroup {
    display: flex;
    gap: 0.75rem;
    margin-top: 0.75rem;
    :global {
      .ant-radio-wrapper {
        width: calc(50% - 0.75rem);
      }
      .customInput:not(:first-child) {
        margin-bottom: 1.25rem;
      }
    }
  }
}

.formDivider {
  border-color: $primaryAlt !important;
  width: 100%;
  margin: 0 !important;
}

.checkBoxWrapper {
  margin-top: 3.75rem;
}

.submitButtonWrapper {
  margin-top: 3.75rem;
}
